.header {
  width: calc(100% - 40px);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 20px;
  background-color: #15151590;
  backdrop-filter: blur(5px); 
  font-family: "Segoe UI Light", "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  position: fixed;
  margin: 0;
  top:0;
}

.header-logo {
  flex: 0 0 auto;
  cursor: pointer;
}

.logo {
  height: 50px; /* Adjust size as needed */
  width: 200px;
  background-image: url("../assets/images/LogoSignature512x2048.png");
  background-size: cover;
  background-repeat: no-repeat;
  cursor: pointer;
}

.header-nav {
  display: flex;
  gap: 30px;
  padding-right: 20px;
}

.nav-link {
  text-decoration: none;
  color: #333; /* Dark text color */
  font-size: 18px;
  font-weight: 500;
  color: white;
  font-family: "Segoe UI Light", "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  cursor: pointer;
}

.nav-link:hover {
  color: #116466; /* Highlight color on hover */
}
