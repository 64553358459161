.app {
  background-color: #151515;
  text-align: center;
  font-family: "Segoe UI Light", "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  display: block;
}
.headlineBox {
  text-align: center;
  width: 100%;
  margin: auto;
  margin-top: 40vh;
}
.headline {
  font-size: 40px;
  color: #116466;
}

.white {
  color: white;
}

.arrowdown {
  position: fixed;
  z-index: 100;
  background-image: url("./assets/images/arrowdown.png");
  background-size: cover;
  margin-left: auto;
  margin-right: auto;
  width: 40px;
  height: 40px;
  bottom: 20px;
  left: 50%;
  transform: translate(-20px, 0px);
  filter: brightness(0.8);
  cursor: pointer;
}
.arrowdown:hover {
  filter: brightness(1);
  cursor: pointer;
}

.color1 {
  background-color: #116466;
}
.color2 {
  background-color: #2c3531;
}
.color3 {
  background-color: #d9b08c;
}
.color4 {
  background-color: #d1e8e2;
}
.basicTextBox {
  width: 80%;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
  text-align: justify;
}
.basicTitle {
  color: gainsboro;
  font-size: 28px;
}
.basicText {
  color: gainsboro;
  font-size: 16px;
  margin-top: 20px;
  line-height: 1.5;
}
.basicImage {
  margin-top: 80px;
  margin-left: auto;
  margin-right: auto;
}
.dataImage {
  background-image: url("./assets/images/data.svg");
  background-size: cover;
  width: 330px;
  height: 280px;
}
.decisionImage {
  background-image: url("./assets/images/analysis.svg");
  background-size: cover;
  width: 345px;
  height: 280px;
}
.executionImage {
  background-image: url("./assets/images/decision.svg");
  background-size: cover;
  width: 530px;
  height: 280px;
}
.analysisImage {
  background-image: url("./assets/images/execution.svg");
  background-size: cover;
  width: 410px;
  height: 280px;
}

.pageBreak {
  height: 25vh;
  width: 100%;
}

/* Contact Form Container */
.contactFormContainer {
  display: block;
}

/* Form Labels */
.formLabel {
  display: block;
  color: gainsboro;
  font-size: 16px;
  margin-bottom: 5px;
  text-align: left;
}

/* Form Inputs */
.formInput,
.formTextarea {
  width: calc(100% - 15px);
  max-width: calc(100% - 15px);
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #116466;
  border-radius: 5px;
  font-size: 14px;
  background-color: #2c3531;
  color: white;
  font-family: "Segoe UI Light", "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}

/* Form Button */
.formButton {
  width: 100%;
  max-width: 200px;
  padding: 10px 15px;
  font-size: 16px;
  color: #151515;
  background-color: #116466;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.formButton:hover {
  filter:brightness(1.2);
}

/* Status Message */
.statusMessage {
  margin-top: 10px;
  font-size: 14px;
  color: gainsboro;
}
