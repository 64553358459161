.footer {
  width: calc(100% - 40px);
  display: flex;
  justify-content: space-between;
  align-items: flex-end; /* Align items to the bottom of the footer */
  padding: 20px 20px;
  background: linear-gradient(to top, #151515 40%, transparent 90%);
  filter: blur(0.8);
  font-family: "Segoe UI Light", "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  position: fixed;
  margin: 0;
  bottom: 0;
  height: 120px;
}

.footer-nav {
  display: flex;
  gap: 20px;
  padding-right: 20px;
}

.footer-link {
  text-decoration: none;
  color: gainsboro;
  font-size: 16px;
  font-weight: 500;
  font-family: "Segoe UI Light", "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  cursor: pointer;
}

.footer-link:hover {
  color: #116466; /* Highlight color on hover */
}

.footer-copyright {
  margin-left: auto;
  color: gainsboro;
  font-size: 16px;
  font-weight: 500;
  font-family: "Segoe UI Light", "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}